@import url('https://fonts.googleapis.com/css2?family=Fira+Sans+Condensed:wght@400;500;600;700&family=Lato:wght@300;400;700;900&display=swap');
@import url('https://cdn.materialdesignicons.com/7.4.47/css/materialdesignicons.min.css');
@import '~vuetify/dist/vuetify.min.css';
// @import '~leaflet/dist/leaflet.css';
// @import '~leaflet-draw/dist/leaflet.draw.css';
@import '~vue-datetime/dist/vue-datetime.css';
@import 'scrollbar_chrome';
@import 'utils';

@import 'trailer';

.v-data-table__wrapper {
  &::-webkit-scrollbar{
    margin-top: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(126, 124, 124, 0.76);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  table tr td {
    padding: 10px 5px;
  }
}

.v-menu__content.v-menu__content--fixed.menuable__content__active.v-autocomplete__content {
  &::-webkit-scrollbar{
    margin-top: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(126, 124, 124, 0.76);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.8);
  }
}

.better-scrollbar, .v-color-picker__swatches, .map__panel--content {
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(126, 124, 124, 0.76);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
}

h1, h2, h3, h4, h5, h6, th, .v-card__title, .title--text {
  font-family: 'Lato', helvetica, sans-serif;
  font-weight: 400 !important;
}

p, a, td, span, li, ol, .v-list-item, .v-list-item__title, .v-toolbar__title, .v-text-field, .v-footer, input {
  font-family: 'Fira Sans Condensed', helvetica, sans-serif;
  font-weight: 400 !important;
}

b, strong {
  font-family: 'Fira Sans Condensed', helvetica, sans-serif;
  font-weight: 700 !important;
}

small, blockquote, .v-tab, .v-label, label, div {
  font-family: 'Fira Sans Condensed', helvetica, sans-serif;
  font-weight: 300 !important;
}

.monitor-header {
  font-size: 1em !important;
  text-align: center !important;
}

.component-fade-enter-active, .component-fade-leave-active {
  transition: opacity 0.3s ease;
}
.component-fade-enter-from, .component-fade-leave-to {
  opacity: 0;
}

.selected-sidebar {
  background-color: #fff !important;
  .v-list-item__avatar .v-icon, .v-list-item__title {
    color: var(--v-primary-base) !important;
  }
}

.selected-sidebar-dark, .selected-sidebar-dark:before {
  background-color: var(--v-secondary-base) !important;

  .v-list-item__avatar .v-icon, .v-list-item__title {
    color: #fff !important;
  }
}

.font-family__ {
  &fira_sans {
    font-family: "Fira Sans Extra Condensed", helvetica, sans-serif !important;
    i,
    span {
      font-family: "Fira Sans Extra Condensed", helvetica, sans-serif !important;
    }
  }

  &courgette {
    font-family: "Courgette", cursive !important;
    i,
    span {
      font-family: "Courgette", cursive !important;
    }
  }
}

.v-timeline-item__dot {
  background: rgba(255, 255, 255, 0.8) !important;
}

.snackbar-transition {
  transition: all 500ms ease-in-out;
}

.selected span table tr td {
  color: #fff;
}

.unit-info-table {
  font-weight: 200 !important;
  font-size: 12px;
  text-align: center;
  width: 33%;
}

.v-card, .v-dialog, .v-expansion-panel.v-expansion-panel--active.v-item--active {
  // border-radius: 4px;
  border-radius: 8px !important;
}

.no__click {
  pointer-events: none;
}

.v-expansion-panel-content__wrap{
  padding: 0px !important;
}

.circle{
  border-radius: 50%;
}

.gm-div-icon {
  span {
    text-align: center;
    color: #000;
    font-weight: bold !important;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    padding: 5px 10px;
    font-size: 1rem;
    // background: rgba($color: #001E60, $alpha: 0.5);
    // border-radius: 30px;
  }
}


.gm_map--car_icon{
  width: 32px;
  height: 32px;
}

.leaflet-draw.leaflet-control {
  margin: 0 !important;
}

.leaflet-draw-section div {
  border: none !important;
}

.leaflet-draw-actions {
  margin: 0 !important;
  padding: 4px 30px !important;

  li a {
    background: rgba($color: #212121, $alpha: 0.8) !important;
  }

  li:first-child a {
    border-top-left-radius: 50px !important;
    border-bottom-left-radius: 50px !important;
  }

  li:last-child a {
    border-top-right-radius: 50px !important;
    border-bottom-right-radius: 50px !important;
  }
}

.leaflet-draw-toolbar a {
  background-image: none !important;
  background-color: #fff !important;
  position: relative;

  width: 36px !important;
  height: 36px !important;
  border-radius: 100% !important;
  border: none !important;
  margin: 8px !important;

  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
              0 6px 10px 0 rgba(0, 0, 0, 0.14),
              0 1px 18px 0 rgba(0, 0, 0, 0.12) !important;
}

.leaflet-draw-toolbar {
  .leaflet-draw-draw-polygon {
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-image: url('./map_icons/pentagon-outline.svg') !important;
      background-size: contain !important;
      background-position: center !important;
      background-repeat: no-repeat;

      width: 24px;
      height: 24px;
    }
  }

  .leaflet-draw-draw-circle {
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-image: url('./map_icons/checkbox-blank-circle-outline.svg') !important;
      background-size: contain !important;
      background-position: center !important;
      background-repeat: no-repeat;

      width: 24px;
      height: 24px;
    }
  }

  .leaflet-draw-edit-edit {
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-image: url('./map_icons/square-edit-outline.svg') !important;
      background-size: contain !important;
      background-position: center !important;
      background-repeat: no-repeat;

      width: 24px;
      height: 24px;
    }
  }

  .leaflet-draw-edit-remove {
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-image: url('./map_icons/delete.svg') !important;
      background-size: contain !important;
      background-position: center !important;
      background-repeat: no-repeat;

      width: 24px;
      height: 24px;
    }
  }
}

.v-chip .v-chip__content {
  width: 100% !important;
}


#formDatetime {
  position: absolute;
  z-index: 1;
  transform: translate(20%, 78%);
  opacity: 0;
}

.form-datetime .vdatetime-popup__header,
.form-datetime .vdatetime-calendar__month__day--selected > span > span,
.form-datetime .vdatetime-calendar__month__day--selected:hover > span > span {
  background: var(--v-primary-base);
  font-family: "Fira Sans Extra Condensed", helvetica, sans-serif;
  font-weight: 400 !important;
}

.form-datetime .vdatetime-year-picker__item--selected,
.form-datetime .vdatetime-time-picker__item--selected,
.form-datetime .vdatetime-popup__actions__button {
  color: var(--v-primary-base);
  font-family: "Fira Sans Extra Condensed", helvetica, sans-serif;
  font-weight: 400 !important;
}

.form-datetime .vdatetime-calendar__month__day,
.form-datetime .vdatetime-year-picker__item,
.form-datetime .vdatetime-time-picker__item {
  font-family: "Fira Sans Extra Condensed", helvetica, sans-serif;
  font-weight: 400 !important;
}

.triangle {
  fill: #0f0;
  stroke: #0f0;
  stroke-width: 10;
}


.custom-clustericon {
  background: var(--cluster-color);
  color: #fff;
  border-radius: 100%;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  align-items: center;
  position: relative;
}

.custom-clustericon::before,
.custom-clustericon::after {
  content: "";
  box-sizing: initial;
  position: absolute;
  width: 100%;
  height: 100%;

  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  background: var(--cluster-color);
  opacity: 0.2;
  border-radius: 100%;
}

.custom-clustericon::before {
  padding: 7px;
}

.custom-clustericon::after {
  padding: 14px;
}

.custom-clustericon-1 {
  --cluster-color: #42a5f5;
}

.custom-clustericon-2 {
  --cluster-color: #ffb300;
}

.custom-clustericon-3 {
  --cluster-color: #e57373;
}

.geozone-marker-label {
  color: black;
  font-weight: bold !important;
  text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
  transform: translatey(26px);
}

.date-picker__sidebar-color {
  &.theme--light {
    > .v-picker__body {
      background-color: #EEEEEE !important;
    }
  }

  &.theme--dark {
    > .v-picker__body {
      background-color: #424242 !important;
    }
  }
}

.text-linked {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
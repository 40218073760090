

.text{
  text-align: left;
  font-size: 17px;
  letter-spacing: 0px;
  color: #787878;
  opacity: 1;
  margin: 0px;
}

.tyre_pill__card {
  transition: all 0.5s ease;
  border-radius: 20px !important;
  box-shadow: 0px 0px 6px #00000029 !important;
  border: 1px solid #707070 !important;

  &.highlight {
    box-shadow: 0px 0px 10px #73160ED5 !important;
    border: 4px solid #73160E !important;
  }
}

.info_menu__card{
  border: 1px solid #7070705E !important;
  border-radius: 15px !important;
  .info--title{
    text-transform: uppercase;
  }
  .info--value{
    font-size: 18px;
  }
}



.list__label {
  font-size: 24px;
}

.label__icon{
  font-size: 28px;
}




.unit__icon{
  font-size: 28px;
}

.unit__title{
  justify-content: center;
  font-size: 30px;
  font-weight: 700 !important;
}

.unit__subtitle {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}




.list--container {
  height: 100%;
  overflow: auto;
}

.unit-list-title {
  font-size: 0.71rem;
}

.striped--divider {
  height: 2px;
  background: linear-gradient(-70deg,transparent 35%,#FFF 45%, #FFF 55%,transparent 65%);
  background-size: 8px 2px;
  border: none;
}

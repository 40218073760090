$prioritiesColors: ("priority_0": #C1C1C1,
  "priority_1": #73B269,
  "priority_2": #FBC782,
  "priority_3": #C46868);

$activeColors: ("priority_0": #9E9E9E,
  "priority_1": #4CAF50,
  "priority_2": #FFA631,
  "priority_3": #C62828);

$wheels: "NSR",
"NSM",
"NSF",
"OSR",
"OSM",
"OSF",
"NSRI",
"NSMI",
"NSFI",
"NSRO",
"NSMO",
"NSFO",
"OSRI",
"OSMI",
"OSFI",
"OSRO",
"OSMO",
"OSFO";

.trailer__container {
  @each $wheel in $wheels {

    @each $priority,
    $color in $prioritiesColors {
      &.wheel_#{$wheel}--#{$priority} {

        g#wheel-#{$wheel} {
          cursor: pointer;

          &>path {
            fill: $color !important;
          }

          &:hover {
            &>path {
              fill: map-get($map: $activeColors, $key: $priority) !important;
            }

            g path {
              fill: #000000 !important;
            }
          }

          g path {
            fill: #000000 !important;
          }
        }

      }
    }
  }
}
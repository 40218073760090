

.container {
  position: relative;
  height: 64px;
}

.expantion--absolute {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.no--elements--text {
  text-transform: uppercase;
}



.button-container {
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.list-container {
  width: calc(calc(100% - 100px) / 2);
}

@media screen and (max-width: 811px) {
  .list-container {
    width: 100%;
  }

  .button-container {
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .button1 {
      transform: rotate(90deg);
    }

    .button2 {
      transform: rotate(90deg);
    }
  }
}


.sensor--pill-text {
  text-align: left;
  font-size: 24px;
  letter-spacing: -0.3px;
  color: #7E7E7E;
  opacity: 1;
  font-weight: bold !important;
}

.sensor--pill-title {
  text-align: left;
  font-size: 20px;
  letter-spacing: -0.3px;
  color: #7E7E7E;
  opacity: 1;
}

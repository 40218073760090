.scrollbar__seamless{
  //Chrome and updated browsers Only
  &::-webkit-scrollbar {
    margin-top: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(126, 124, 124, 0.76);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
}




.inverted_expandable {
  position: relative;

  .content {
    position: absolute;
    z-index: 3;
    left: 0;
    right: 0;
    overflow: hidden;

    transition: height 0.5s;
    height: 0px;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;

    &.active--expanded {
      animation-play-state: running;
      animation-name: unfold;
    }
  }

  .header {
    position: relative;
    cursor: pointer;
  }
}

@keyframes unfold {
  0% {
    overflow: hidden;
  }

  99% {
    overflow: hidden;
  }

  100% {
    overflow: visible;
  }
}

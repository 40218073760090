.counter__circle {
  border-radius: 50%;
  width: 28px;
  height: 28px;
}

.gm_black--text{
  color: #515151 !important;
}

.label__subtitle {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.circle{
  border-radius: 50%;
}

.chart-gradient {
  background: rgb(239, 154, 154);
  background: linear-gradient(
    180deg,
    rgba(239, 154, 154, 1) 0%,
    rgba(255, 224, 130, 1) 50%,
    rgba(165, 214, 167, 1) 100%
  );
}
.chart-gradient-reverse {
  background: rgba(165, 214, 167, 1) 0%;
  background: linear-gradient(
    180deg,
    rgba(165, 214, 167, 1) 0%,
    rgba(255, 224, 130, 1) 50%,
    rgba(239, 154, 154, 1) 100%,
  );
}
.loader{
  position: absolute;
  z-index: 4;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(33, 33, 33, 0.46);
}



.list_title{
  border-bottom: 2px solid;
  border-color: #616161;
}

.tabs__styles {
  font-size: 16px;
}

.mapfiller{
  height: 400px;
  background-color: #67925b;
  width: 100%;
}

.image__container {
  width: 100%;
  height: 90px;
  display: flex; 
  justify-content: center;
  align-items: center;
  background: rgb(151,151,153);
  background: linear-gradient(180deg, rgba(151,151,153,1) 0%, rgba(185,185,185,1) 0%, rgba(247,249,250,1) 100%);
}

.card__actions {
  margin-top: -60px
}

.text__container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

.green__text {
  color: #0B6E00;
  font-weight: bold !important;
}

.snackbar__container {
  background-color: #328428;
  color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0px 2px 8px #5D5D5D54;
  padding: 4px 12px;
  height: 100%;
}

.warning__snackbar__container {
  background-color: #F9A825;
}

.snackbar__title {
  color: #FFFFFF;
  font-weight: bold !important;
}

.snackbar__subtitle {
  color: #FFFFFF;
  font-size: 12px;
}

.link__container {
  color: #014E88;
}

.loader{
  position: absolute;
  z-index: 4;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(33, 33, 33, 0.46);
}


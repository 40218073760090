
.map__form--container{
  position: absolute;
  width: 35%;
  top: 0;
  left: 0;
}
.map__form--actions{
  position: absolute;
  width: 12%;
  bottom: 30px;
  right: 0;
}

.map__form--geofence_mode {
  position: absolute;
  top: 0;
  left: 50%;

}

